// Navbar
.navbar {
  height: auto;
  max-width: calc(#{$wide-size} - (#{$spacing-full} * 2));
  max-width: -webkit-calc(#{$wide-size} - (#{$spacing-full} * 2));
  position: relative;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 1px solid $light;
  padding: $spacing-full - 15px $spacing-full;
  @extend %clearfix;
}

// Navigation
.menu {
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  
  a#mode {
    float: left;
    left: 8px;
    top: 6px;
    position: relative;
    clear: both;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.7;
    z-index: 1;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
    &:active {
      -webkit-transform: scale(0.9, 0.9);
      transform: scale(0.9, 0.9);
    }
    .mode-moon {
      display: block;
      line {
        stroke: $black;
        fill: none;
      }
      
      circle {
        fill: $black;
        stroke: $black;
      }
    }
    .mode-sunny {
      display: none;
      line {
        stroke: $dark-white;
        fill: none;
      }
      circle {
        fill: none;
        stroke: $dark-white;
      }
    }
  }
  
  .trigger {
    float: right;
  }
  
  .menu-trigger {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  .menu-link {
    color: $black;
    line-height: $base-line-height + 0.4;
    text-decoration: none;
    padding: 5px 8px;
    opacity: 0.7;
    letter-spacing: 0.3px;
    
    &:hover {
      opacity: 1;
    }
    
    &:not(:last-child) {
      margin-right: 5px;
    }
    
    &.rss {
      position: relative;
      bottom: -3px;
      outline: none;
    }
    
    @include media-query($on-mobile) {
      opacity: 0.8;
    }
  }
  
  .menu-link.active {
    opacity: 1;
    font-weight: 600;
  }
  
  @include media-query($on-mobile) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    text-align: center;
    height: 50px;
    background-color: $white;
    border-bottom: 1px solid $light;
    
    a#mode {
      left: 10px;
      top: 12px;
    }
    
    .menu-icon {
      display: block;
      position: absolute;
      right: 0;
      width: 50px;
      height: 23px;
      line-height: 0;
      padding-top: 13px;
      padding-bottom: 15px;
      cursor: pointer;
      text-align: center;
      z-index: 1;
      > svg {
        fill: $black;
        opacity: 0.7;
      }
      &:hover {
        > svg {
          opacity: 1;
        }
      }
      &:active {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
      }
    }
    
    input[type="checkbox"]:not(:checked) ~ .trigger {
      clear: both;
      visibility: hidden;
    }
    
    input[type="checkbox"]:checked ~ .trigger {
      position: fixed;
      animation: 0.2s ease-in forwards fadein;
      -webkit-animation: 0.2s ease-in forwards fadein;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: $white;
      height: 100vh;
      width: 100%;
      top: 0;
    }
    
    .menu-link {
      display: block;
      box-sizing: border-box;
      font-size: 1.1em;
      
      &:not(:last-child) {
        margin: 0;
        padding: 2px 0;
      }
    }
  }
}

// Author
.author {
  margin-top: 6.3rem;
  margin-bottom: 7.2rem;
  text-align: center;
  
  @include media-query($on-mobile) {
    margin-bottom: 3em;
  }
  
  .author-avatar {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    user-select: none;
    background-color: $black;
    -ms-user-select: none;
    -webkit-user-select: none;
    -webkit-animation: 0.5s ease-in forwards fadein;
    animation: 0.5s ease-in forwards fadein;
    opacity: 1;
  }
  
  .author-name {
    font-size: 1.7em;
    margin-bottom: 2px;
  }
  
  .author-bio {
    margin: 0 auto;
    opacity: 0.9;
    width: auto;
    line-height: 1.688;
  }
  
  .author-social-media {
    .linkedin:hover {
      background-color: #0e76a8;
      color: #fff;
    }
    .instagram:hover {
      background-color: #c32aa3;
      color: #fff;
    }
    .telegram:hover {
      background-color: #0088cc;
      color: #fff;
    }
  }
  
}

// Content
.posts-item-note {
  font-size: $base-font-size;
  font-weight: 700;
  margin-bottom: 5px;
  color: $black;
}

// List of posts
.post-item {
  display: flex;
  padding-top: 5px;
  padding-bottom: 6px;
  @extend %clearfix;
  
  &:not(:first-child) {
    border-top: 1px solid $light;
  }
  
  .post-item-date {
    min-width: 96px;
    color: $black;
    font-weight: 700;
    padding-right: 10px;
    
    @include media-query($on-mobile) {
      font-size: 16px;
    }
  }
  
  .post-item-title {
    margin: 0;
    border: 0;
    padding: 0;
    font-size: $base-font-size;
    font-weight: normal;
    letter-spacing: 0.1px;
    
    a {
      color: $text-base-color;
      
      &:hover,
      &focus {
        color: $black;
      }
    }
  }
}

// Footer
.footer {
  margin-top: 8em;
  margin-bottom: 2em;
  text-align: center;
  
  @include media-query($on-mobile) {
    margin-top: 3em;
  }
  span.footer_item {
    color: $black;
    opacity: 0.8;
    font-weight: $bold-weight;
    font-size: $small-font-size;
  }
  a.footer_item {
    color: $black;
    opacity: 0.8;
    text-decoration: none;
    
    &:not(:last-child) {
      margin-right: 10px;
      &:hover {
        opacity: 1;
      }
    }
  }
  
  .footer_copyright {
    font-size: $small-font-size - 1;
    margin-top: 3px;
    display: block;
    color: $gray;
    opacity: 0.8;
  }
}

.not-found {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  .title {
    font-size: 5em;
    font-weight: $bold-weight;
    line-height: 1.1;
    color: $black;
    text-shadow: 1px 0px 0px $text-link-blue;
  }
  .phrase {
    color: $text-base-color;
  }
  .solution {
    color: $text-link-blue;
    letter-spacing: 0.5px;
  }
  .solution:hover {
    color: $text-link-blue-active;
  }
}


.search-article {
  position: relative;
  margin-bottom: 50px;
  
  label[for="search-input"] {
    position: relative;
    top: 10px;
    left: 11px;
  }
  
  input[type="search"] {
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 40px;
    outline: none;
    position: absolute;
    border-radius: 5px;
    padding: 10px 10px 10px 35px;
    color: $text-base-color;
    font-size: $base-font-size;
    background-color: rgba(128, 128, 128, 0.1);
    border: 1px solid rgba(128, 128, 128, 0.1);
    &::-webkit-input-placeholder {
      color: #808080;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

#search-results {
  text-align: center;
  li {
    text-align: left;
  }
}

.archive-tags {
  height: auto;
  .tag-item {
    padding: 1px 3px;
    border-radius: 2px;
    border: 1px solid rgba(128, 128, 128, 0.1);
    background-color: rgba(128, 128, 128, 0.1);
  }
}

// Contact
.contact {
  .container {
    border-radius: 5px;
    opacity: 0.9;
    padding: 20px;
    label {
      display: inline-block;
    }
    input[type=text], input[type=email], input[type=tel], select, textarea {
      width: 100% !important;
      padding: 12px;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      box-sizing: border-box;
      margin-top: 6px;
      margin-bottom: 16px;
      resize: vertical;
    }
    .iti {
      position: relative !important;
      display: block !important;
      margin: 6px 0px 16px !important;
    }
    button[type=submit] {
      background-color: #fcb13e;
      padding: 12px 20px;
      border: 1px solid #fcb13e;
      border-radius: 4px;
      cursor: pointer;
      color: #fff;
    }
    button[type=submit]:hover {
      background-color: #fc910d;
      border: 1px solid #fc910d;
      color: #fff;
    }
    label {
      .mark {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        color: red;
      }
    }
  }
  .lottie {
    dotlottie-player {
      margin: auto !important;
    }
  }
  .success-message {
    text-align: center;
    h2, p {
      margin: 0;
    }
    .history-back {
      margin-top: 2rem;
      .back:hover {
       background-color: #f8f9fa;
      }
    }
  }
}

// No Hello
.nohello {
  .hero {
    margin: 0px auto 100px auto;
    position: relative;
    .name {
      font-size: 96px;
      font-weight: 700;
      display: inline-block;
      text-decoration-color: #fff;
      #strike {
        text-decoration: line-through;
        text-decoration-color: red;
        display: inline;
      }
    }
    .subtitle {
      display: block;
      font-size: 27px;
      font-weight: 300;
    }
    .lead {
      font-size: 44px;
      font-weight: 300;
      margin-top: 60px;
      line-height: 55px;
    }
  }
  .section {
    .section-title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
  .list-card {
    width: 100% !important;
    padding: 25px 25px 10px 25px;
    .slack {
      padding-bottom: 15px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 5px;
        float: left;
        margin-right: 10px;
      }
    }
    .slackname {
      font-weight: 600;
      font-size: 16px;
    }
    .slacktime {
      font-size: 14px;
    }
    .slackmessage {
      font-size: 16px;
    }
  }
}

// Button
.button {
  box-sizing: border-box;
  font-family: inherit;
  text-transform: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,
  box-shadow .15s ease-in-out;
  opacity: 0.9;
  background-color: transparent;
  border-color: transparent;
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
  cursor: pointer;
  margin: .25rem .125rem;
  svg {
    position: relative;
    bottom: -3px;
    outline: none;
    margin-right: .5rem !important;
  }
}